import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {GetCountries} from './countries.action';
import {CountriesStateModel} from './countries.state';
import {CountryModel} from '@matchsource/models/country';

const byId = (state: CountriesStateModel) => (id: MsApp.Guid) => state.entities[id];

@Injectable({
  providedIn: 'root',
})
export class CountriesService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public readonly data$: Observable<CountriesStateModel>;

  public readonly loading$: Observable<boolean>;

  public readonly countryList$: Observable<CountryModel[]>;

  public readonly restrictedCountryList$: Observable<CountryModel[]>;

  constructor(private readonly store: Store) {
    this.data$ = this.store
      .select((state: any) => state.countries as CountriesStateModel)
      .pipe(
        distinctUntilChanged(),
        tap(countryState => {
          if (!countryState.loaded && !countryState.loading) {
            this.store.dispatch(new GetCountries());
          }
        }),
        takeUntil(this.destroy$),
        shareReplay({refCount: true, bufferSize: 1})
      );

    this.loading$ = this.data$.pipe(map(state => state.loading));

    this.countryList$ = this.data$.pipe(
      map((state: CountriesStateModel) => {
        return state.ids.map(code => state.entities[code]);
      })
    );

    this.restrictedCountryList$ = this.data$.pipe(
      map((state: CountriesStateModel) =>
        state.ids.map(code => state.entities[code]).filter(item => !item.isPatientRestricted)
      )
    );
  }

  getById(id: MsApp.Guid): Observable<CountryModel> {
    return combineLatest([of(id), this.data$]).pipe(map(([countryId, data]) => byId(data)(countryId)));
  }

  isPatientRestricted(code: string): Observable<boolean> {
    return this.getById(code).pipe(map(country => country?.isPatientRestricted ?? false));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
